import React from "react"
import tw from "twin.macro"

// Components
import Accordion from "@components/accordion"
import StaticHero from "@components/staticHero"
import Anchor from "@components/Anchor"
import Container from "@components/container"
import Layout from "@components/layout"
import PrimaryCta from "@components/PrimaryCta"
import Seo from "@components/seo"
import { Heading2 } from "@styles/ui"

const faqs = [
  {
    heading: <>What is XYWAV?</>,
    content: (
      <>
        <p>
          XYWAV is the first and only low-sodium oxybate approved by the FDA to
          treat cataplexy or excessive daytime sleepiness in people 7&nbsp;years
          and older with narcolepsy.
        </p>
        <p>
          A clinical trial demonstrated the efficacy and safety of XYWAV for the
          treatment of cataplexy and excessive daytime sleepiness in adult
          patients with narcolepsy.
        </p>
        <span tw="block text-[15px] mt-[30px] xl:mt-0">
          <Anchor link="/narcolepsy/what-is-xywav/" hasArrow>
            Learn more about XYWAV
          </Anchor>
        </span>
      </>
    ),
  },
  {
    heading: (
      <>
        What is the difference between XYREM<sup>&reg;</sup> (sodium oxybate)
        oral solution, a high-sodium oxybate, and XYWAV?
      </>
    ),
    content: (
      <>
        <p>
          XYWAV has the same active ingredient, oxybate, but with 92% (~1509 mg)
          less sodium than XYREM at the maximum recommended 9&#8209;g nightly
          dose. Choosing XYWAV over XYREM can help reduce the risk for
          developing cardiovascular disease from excessive sodium.
        </p>
        <span tw="block text-[15px] mt-[30px] xl:mt-0">
          {" "}
          <Anchor link="/narcolepsy/transitioning-from-xyrem/" hasArrow>
            Learn more about transitioning to XYWAV
          </Anchor>
        </span>
      </>
    ),
  },
  {
    heading: (
      <>How much sodium is in XYWAV compared to XYREM, a high-sodium oxybate?</>
    ),
    content: (
      <>
        <p>
          XYWAV has 131 mg of sodium at the maximum 9&#8209;g nightly dose.
          That&apos;s 92% (~1509 mg) less sodium than XYREM. So choosing XYWAV
          over XYREM can help reduce excessive sodium intake, a modifiable risk
          factor for developing cardiovascular disease.
        </p>
        <span tw="block text-[15px] mt-[30px] xl:mt-0">
          <Anchor
            link="/narcolepsy/lower-sodium-oxybate-treatment-option/"
            hasArrow
          >
            Learn more about XYWAV
          </Anchor>
        </span>
      </>
    ),
  },
  {
    heading: <>Is there an artificial sweetener in XYWAV? If so, which one?</>,
    content: (
      <>
        <p>XYWAV contains sucralose.</p>
      </>
    ),
  },
  {
    heading: <>What are the possible side effects of XYWAV?</>,
    content: (
      <>
        {" "}
        <p>
          The most common side effects of XYWAV in adults include headache,
          nausea, dizziness, decreased appetite, parasomnia (a sleep disorder
          that can include abnormal dreams, abnormal rapid eye movement (REM)
          sleep, sleep paralysis, sleep talking, sleep terror, sleep-related
          eating disorder, sleepwalking, and other abnormal sleep-related
          events), diarrhea, excessive sweating (hyperhidrosis), anxiety, and
          vomiting. In patients who remained on treatment, side effects tended
          to occur early and diminish over time.
        </p>
        <p>
          In children, the most common side effects include nausea, bedwetting,
          vomiting, headache, weight decrease, decreased appetite, dizziness,
          and sleepwalking.
        </p>
        <p>
          These are not all the possible side effects of XYWAV. For more
          information, ask your doctor or pharmacist. Call your doctor for
          medical advice about side effects.
        </p>
        <p tw="text-studio font-extrabold">Please see:</p>
        <span tw="block text-[15px] mb-4">
          <Anchor
            link="https://pp.jazzpharma.com/pi/xywav.en.USPI.pdf"
            newTab
            hasArrow
          >
            Full Prescribing Information, including BOXED Warning
          </Anchor>
        </span>
        <span tw="block text-[15px] mb-4">
          <Anchor
            link="https://pp.jazzpharma.com/pi/xywav.en.MG.pdf"
            newTab
            hasArrow
          >
            Medication Guide (English)
          </Anchor>
        </span>
        <span tw="block text-[15px] mt-0 xl:mt-0!">
          <Anchor
            link="https://pp.jazzpharma.com/pi/xywav.sp.MG.pdf"
            newTab
            hasArrow
          >
            Medication Guide (Spanish)
          </Anchor>
        </span>
      </>
    ),
  },
  {
    heading: <>Why can’t I get XYWAV at my local pharmacy?</>,
    content: (
      <>
        <p>
          Because of the risk of central nervous system (CNS) depression, abuse,
          and misuse, XYWAV is available only through a restricted distribution
          program called the XYWAV and XYREM REMS. You or your child must be
          enrolled in this program to receive XYWAV.
        </p>{" "}
        <p>
          In addition, the Certified Pharmacy is the only pharmacy in the United
          States that is permitted to fill your XYWAV prescription and send it
          directly to you.
        </p>
        <span tw="block text-[15px] mt-[30px] xl:mt-0">
          <Anchor link="/narcolepsy/jazzcares-for-xywav/" hasArrow>
            Learn more
          </Anchor>
        </span>
      </>
    ),
  },
  {
    heading: <>Does XYWAV taste the same as XYREM?</>,
    content: (
      <>
        <p>
          Because XYWAV contains calcium, magnesium, and potassium, it will
          taste different than XYREM.
        </p>
      </>
    ),
  },
  {
    heading: <>How much does XYWAV cost?</>,
    content: (
      <>
        <p>
          Jazz Pharmaceuticals is committed to making sure that people who are
          prescribed XYWAV can get it affordably. The JazzCares<sup>&reg;</sup>{" "}
          team will help you navigate the insurance process so you can start
          XYWAV as soon as possible. JazzCares for XYWAV also offers several
          programs to help qualified patients with the cost of treatment.
          <sup>*†‡</sup>
        </p>
        <p>
          <span tw="block text-[0.72rem] ml-1">
            <span tw="-ml-1">*</span>Subject to a maximum annual benefit.
          </span>
          <span tw="block text-[0.72rem] ml-1">
            <span tw="-ml-1">
              <sup>†</sup>
            </span>
            You must be a resident of the US, Puerto Rico, or other US
            territory.
          </span>
          <span tw="block text-[0.72rem] ml-1">
            <span tw="-ml-1">
              <sup>‡</sup>
            </span>
            You must be enrolled in the XYWAV and XYREM REMS.
          </span>
        </p>
        <span tw="block text-[15px] mt-[30px] xl:(mt-0 text-xl)">
          <Anchor link="/narcolepsy/jazzcares-for-xywav/" newTab hasArrow>
            Learn about financial assistance options for qualified patients
          </Anchor>
        </span>
      </>
    ),
  },
  {
    heading: <>Why did Jazz Pharmaceuticals make XYWAV?</>,
    content: (
      <>
        <p>
          XYREM contains ~1640 mg of sodium per day at the maximum recommended
          nightly dose for adults. XYWAV, with 131 mg of sodium per maximum
          recommended total nightly dose for adults, was developed to provide a
          lower-sodium oxybate option.
        </p>

        <span tw="block text-[15px] mt-[30px] xl:mt-0">
          <Anchor link="/narcolepsy/what-is-xywav/" hasArrow>
            Learn more about XYWAV
          </Anchor>
        </span>
      </>
    ),
  },
  {
    heading: <>How do I take XYWAV?</>,
    content: (
      <>
        <p>
          If your doctor prescribes you XYWAV, he or she will give you detailed
          instructions on how to take your nightly doses. You will also receive
          additional materials to ensure that you understand how to mix and take
          your nightly XYWAV doses.{" "}
          <Anchor link="/narcolepsy/dosing/">Learn more</Anchor> about dosing.
        </p>
        <span tw="block text-[15px] mt-[30px] xl:mt-0">
          <Anchor link="https://pp.jazzpharma.com/pi/xywav.en.MG.pdf" hasArrow>
            Please refer to the Medication Guide for detailed information about
            preparing and taking XYWAV
          </Anchor>
        </span>
      </>
    ),
  },
  {
    heading: <>What support is available if my doctor prescribes XYWAV?</>,
    content: (
      <>
        <p tw="text-black">
          JazzCares<sup>&reg;</sup> provides support for people taking XYWAV
          with a team of experts led by a Nurse Case Manager.
        </p>
        <ul tw="ml-2.5">
          <li>
            <Anchor link="/narcolepsy/jazzcares-for-xywav/#jazzcares">
              Nurse and pharmacy support
            </Anchor>
            <span tw="font-extrabold">
              —A dedicated JazzCares Nurse Case Manager
            </span>{" "}
            will be with you from the very beginning of your treatment
            experience and will <strong>help answer questions</strong> you may
            have along the way.
            <br />{" "}
            <span tw="font-extrabold">
              A pharmacist is also available 24/7
            </span>{" "}
            to answer any questions you may have about your prescription through
            the specialty pharmacy that dispenses XYWAV, called the Certified
            Pharmacy.
          </li>
          <li>
            <Anchor link="/narcolepsy/jazzcares-for-xywav/#savings">
              Savings support
            </Anchor>
            —We know that getting your prescription covered is important to you.
            The JazzCares team will help you navigate the insurance process to
            help you start XYWAV as soon as possible. JazzCares for XYWAV also
            offers several programs to help patients with the cost of treatment
            <span tw="font-extrabold">
              —a Nurse Case Manager can help determine if a savings program is
              available to you.{" "}
            </span>
          </li>
          <li>
            <Anchor link="/narcolepsy/patient-resources/" tw="inline">
              Resources for starting and staying on XYWAV
            </Anchor>
            —JazzCares can help you{" "}
            <span tw="font-extrabold">
              start and keep your XYWAV treatment journey on track with
              continuous support
            </span>
            , including access to{" "}
            <span tw="font-extrabold">dedicated nursing assistance</span>,
            prescription refill reminders, and connections with other patients
            through the <span tw="font-extrabold">XYWAV Mentor Program</span>.
            <br />
            You can also register for and download the{" "}
            <span tw="font-extrabold">myWAV app</span>. myWAV is easy to access
            and helps you get started and track your symptoms, organizes
            instructions for use, support programs, and treatment tips, and
            supports you through your treatment.
          </li>
        </ul>
      </>
    ),
  },
]

const FaqPage = ({ location }) => {
  return (
    <Layout location={location} mainBgColour="seashell">
      <Seo
        title="XYWAV Frequently Asked Questions (FAQs) | XYWAV®"
        description="Explore commonly asked questions about XYWAV® (calcium, magnesium, potassium, and sodium oxybates) such as sodium content, cost, and difference between XYREM® and XYWAV®. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <StaticHero eyebrow={<h1>Why XYWAV</h1>} gradient="purple-red">
        <h2 tw="block">Frequently Asked Questions</h2>
      </StaticHero>

      <section tw="bg-white">
        <div tw="w-full xl:(mt-0)">
          <div tw="rounded-lg w-full grid relative z-10 max-w-3xl m-auto py-[3.125rem] px-[4.8%] xl:(py-20 px-[6.125rem] max-w-6xl)">
            {faqs.map((item, i) => {
              return (
                <Accordion
                  key={i}
                  heading={item.heading}
                  content={item.content}
                />
              )
            })}
          </div>
        </div>
      </section>

      <section tw="py-[1.875rem] bg-lilac">
        <Container>
          <div tw="before:(gradientRed block relative w-[124px] mb-[15px] h-1.5 rounded-md left-1/2 transform -translate-x-1/2) md:before:(mb-[25px]) xl:before:(mb-6)"></div>
          <h2 css={[Heading2, tw`mb-[1.875rem] text-center`]}>
            Stay Connected
          </h2>
          <p tw="text-base text-center mb-[1.875rem]">
            Sign up to receive important information about XYWAV treatment and
            narcolepsy.
          </p>
          <div tw="flex justify-center">
            <PrimaryCta
              url={"/narcolepsy/stay-connected/"}
              addedStyles={tw`w-fit`}
            >
              Sign Me Up
            </PrimaryCta>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default FaqPage
